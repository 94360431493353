table {
    width: 100%;
    border: 1px solid #444444;
    border-collapse: collapse;
    td {
        border: 1px solid #444444;
        padding: 10px;
        width: 100px;
        height: 100px;
        text-align: center;
        font-size: xx-large; } }
